<template>
<div class="lesson-templates">
  <h1 class="subtitle-2 grey--text">出席情况</h1>
  <v-container class="my-5">
    <h2 class="title">{{ lessonInfo.title }}</h2>
    <h3 class="caption">
      <v-icon class="subtitle-2">schedule</v-icon>-{{ lessonInfo.time | moment("LLLL") }}
      <v-icon class="subtitle-2">store</v-icon>-{{ lessonInfo.venue_name }}
    </h3>
    <v-layout align-center justify-center row wrap>
      <v-flex xs12 class="pa-3">
        <h3 class="subtitle-2 grey--text"> <v-icon>group</v-icon> 学员签到</h3>
        <v-select
          v-model="studentAttendance"
          :items="students"
          attach
          chips
          label="学员签到"
          multiple
          prepend-inner-icon=""
          clearable
        ></v-select>
      </v-flex>
      <v-flex xs12 md6 class="pa-3">
      <h3 class="subtitle-2 grey--text"> <v-icon>store</v-icon> 活动场地承接情况</h3>
        场地承接方: {{lessonInfo.host_name}}-{{lessonInfo.venue_name}}
        <v-select
          readonly
          v-model="lessonInfo.venue_status_code"
          :items="venueStatusItems"
          item-text="status"
          item-value="code"
          label="选择场地方状态"
          :rules ="fieldRequired"
        ></v-select>
      </v-flex>
      <v-flex x12 md6 class="pa-3">
        <h3 class="subtitle-2 grey--text"> <v-icon>person</v-icon> 主教教师状态</h3>
        任课教师：{{lessonInfo.tutor}}
        <v-select
          readonly
          v-model="lessonInfo.tutor_status_code"
          :items="tutorStatusItems"
          item-text="status"
          item-value="code"
          label="选择教师状态"
          :rules ="fieldRequired"
        ></v-select>
      </v-flex>
    </v-layout>
      <v-btn color="primary" @click="submit">
        <v-progress-circular
          v-if="showWait"
          indeterminate
          color="white"
          :size="20"
          :width="2"
        ></v-progress-circular>
        <span v-if="showWait">&nbsp;</span>
        提交
      </v-btn>
  </v-container>
</div>
</template>

<script>
import ProgramService from '@/services/ProgramService'
import LessonService from '@/services/LessonService'
import util from '@/util'

export default {
  components: {
  },
  data () {
    return {
      lessonId: null,
      lessonInfo: {},
      lessonEnrollmentInfo: [],
      students: [],
      studentAttendance: [],
      showWait: false,
      venueStatusItems: [],
      tutorStatusItems: [],
      fieldRequired: [(value) => !!value || '必填']
    }
  },
  computed: {
  },
  async mounted () {
    this.lessonId = this.$store.state.route.params.lessonId
    console.log('\n#Page Checkin. \nRetrieve lessonId from path params: ', this.lessonId)
    const resLesson = await LessonService.getLessonInfoByLessonId(this.lessonId)
    this.lessonInfo = resLesson.data.lessonInfo
    console.log('\nRetrieve lesson info: ', this.lessonInfo)
    const resEnrollment = await LessonService.getLessonEnrollmentInfo(this.lessonId)
    this.lessonEnrollmentInfo = resEnrollment.data.lessonEnrollmentInfo
    console.log('\nRetrieve lessonEnrollmentInfo: ', this.lessonEnrollmentInfo)
    this.lessonEnrollmentInfo.forEach(enrollmentRecord => {
      // construct the select list items
      this.students.push({
        text: enrollmentRecord.student,
        value: enrollmentRecord.student_id
      })
      if (enrollmentRecord.student_status_code === 2) {
        this.studentAttendance.push(enrollmentRecord.student_id)
      }
    })
    console.log('\nFinish constructing students array: ', this.students)
    // get venueStatusItems
    this.venueStatusItems = await this.getLessonSessionVenueStatusItems()
    this.tutorStatusItems = await this.getLessonSessionTutorStatusItems()
    console.log('\nRetrieved venue and tutor lesson session status items. \nvenueStatusItems: ', this.venueStatusItems, '\ntutorStatusItems: ', this.tutorStatusItems)
    this.lessonInfo.venue_status_code = 2
    this.lessonInfo.tutor_status_code = 2
  },
  methods: {
    async submit () {
      this.showWait = true
      const self = this
      this.lessonEnrollmentInfo.forEach(lessonEnrollment => {
        if (util.inArray(lessonEnrollment.student_id, self.studentAttendance)) {
          lessonEnrollment.student_status_code = 2
        } else {
          lessonEnrollment.student_status_code = 0
        }
        lessonEnrollment.student_status = null
      })
      await LessonService.updateLessonStudentCheckInStatus(this.lessonEnrollmentInfo)
      await LessonService.updateLessonStatus({
        tutor_status_code: this.lessonInfo.tutor_status_code,
        venue_status_code: this.lessonInfo.venue_status_code
      }, this.lessonInfo.id)
      this.showWait = false
      this.$router.push({ name: 'lesson-templates' })
    },
    async getLessonSessionVenueStatusItems () {
      try {
        const response = await ProgramService.getLessonSessionVenueStatusItems()
        console.log('Venue status items received! response.data:', response.data)
        return response.data.statusItems
      } catch (err) {
        return err.response.data
      }
    },
    async getLessonSessionTutorStatusItems () {
      try {
        const response = await ProgramService.getLessonSessionTutorStatusItems()
        console.log('Tutor status items received! response.data:', response.data)
        return response.data.statusItems
      } catch (err) {
        return err.response.data
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
